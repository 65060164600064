
//import axios from 'axios';
import axios from 'axios';
export const url = "https://my-json-server.typicode.com/MalikImansaparov/json-server/news"

// export const instance = axios.create({
//     baseURL: "https://jashtar.gov.kg/",
//     headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//     },
// })
export const uri = 'https://jashtar.gov.kg'
export const base = 'https://jashtar.gov.kg/'
export const enrollUrl = 'd16a80a43d7a3d5a56ed355931766b681f849b2b99a91b99f2ecf10d5d4797df'
export const newsUrl = '19fba0e995b9794fc2c26217bf3b725c2f0d9eeda16719fe75e3ba23ca73bfc4'
export const councilUrl = 'a0c7ec73f6d7f2b4f3e6de3769d290d4f877ff97bad08b675f9abd0c8ee97dff'
export const eventsUrl = '862417b9e7c3720bcb3263cd873b09892d787823b6f9a0f453e42824c5a4d4b6'
export const docsUrl = '9b16b44d0e0a5cac4f968befb0b5b5eba913f9ef3dfa1501ed728b6ead72a357'
export const mainUrl = 'd16a80a43d7a3d5a56ed355931766b681f849b2b99a91b99f2ecf10d5d4797df'
export const galeryUrl = 'ce387d5e0a2972dea9e5129a52ac3b8d58a4d180fc9eece5946d926643a3d2c0'
export const aboutUrl = 'f4116002dd4697f6b3a8434f253e4069674c2b6aeadf0bdba1e8fc683337c11d'
export const searchUrl = 'd16a80a43d7a3d5a56ed355931766b681f849b2b99a91b99f2ecf10d5d4797df'


